.counter {
  margin-top: 15px;
  font-size: 1.5em;
}

@keyframes criticalBlink {
  50% {
    color: red;
  }
}

.critical {
  animation: criticalBlink 0.5s ease-in 0s infinite;
}
