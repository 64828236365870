:root {
  --header-font-size: 2em;
  --header-margin-bottom: 50px;
  --input-font-size: 50px;
  --input-size: 3em;
  --screen-height: 100%;
}

/* On screens that are 480px or less */
@media screen and (max-width: 480px) {
  :root {
    --header-font-size: 2em;
    --header-margin-bottom: 15px;
  }
}

* {
  box-sizing: border-box;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
     Introduced in Internet Explorer 10.
     See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
   */
  -ms-user-select: none;
  user-select: none;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

body {
  overscroll-behavior-y: contain;
  touch-action: pan-y, pan-x;
  overflow-x: hidden;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: "Fredoka One", cursive;

  text-align: center;
  background-color: #21252b;
  color: white;
}

button {
  background-color: #fe6e8a;
}

a {
  color: white;
  text-decoration: none;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  max-width: 540px;
  min-width: 320px;
  position: relative;
  min-height: var(--screen-height);
  /* android status bar & navigation buttons*/
  padding: 30px 15px 60px;
  margin: auto;
}

@media screen and (min-width: 720px) {
  .page-content {
    max-height: 600px;
  }
}

.page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  height: 100%;
}

h2 {
  margin-bottom: 25px;
  font-size: 1.5em;
}

h2 span {
  color: #ff9800;
}

h3 {
  margin-bottom: 25px;
  font-size: 5em;
}

#mute {
  margin-top: 50px;
}

.game-menu i {
  margin-left: 15px;
  cursor: pointer;
}
