.how-to-play {
  padding-top: 30px;
}

.how-to-play i {
  font-size: 1em;
}

.how-to-play hr {
  border: 2px solid white;
  border-radius: 4px;
  width: 30px;
  margin: 30px auto;
}

.how-to-play h2 {
  margin-bottom: 10px;
}

.how-to-play h3 {
  font-size: 18px;
  margin: 10px 0 0;
  color: #fe6e8a;
  display: flex;
  justify-content: center;
  align-items: center;
}

.how-to-play h3 img {
  width: 32px;
  margin: 0 10px;
  width: 32px;
}

h3.heart-title {
  margin-bottom: 10px;
  height: 32px;
}

.how-to-play span {
  color: rgba(83, 206, 236, 1);
}

.content {
  font-weight: normal;
  font-size: 1em;
  line-height: 24px;
  margin-bottom: 15px;
}

.heart-desc {
  display: flex;
}

.heart-desc img {
  vertical-align: center;
}
