button {
  border: none;
  color: white;
  font-family: "Fredoka One", cursive;
  border-radius: 25px;
  font-size: 1.5em;
  margin-bottom: 25px;
  background-color: #f90;
  padding: 5px 25px 8px;
  cursor: pointer;
}

.keyboard {
  width: 100%;
  text-align: right;
  max-width: 210px;
}

.keyboard .button-container {
  display: inline-block;
  width: calc(100% * (1 / 3));
  padding: 5px;
  text-align: center;
  position: relative;
  cursor: pointer;
  transition: transform 50ms ease-out;
}

.keyboard .button-container.disabled {
  opacity: 0.2;
}

.keyboard .button-container.active {
  transform: scale(1.2);
}

.keyboard .button-container.active button {
  border: 2px solid white;
}

.keyboard button {
  width: 100%;
  text-align: center;
  background: #383b41;
  border: 2px solid #383b41;
  padding: 5px 0;
  margin-bottom: 0;
  position: relative;
  font-size: 1.3em;
}

.keyboard button[type="reset"] {
  background-color: #ff9800;
  border-color: #ff9800;
}

.keyboard button[type="submit"] {
  background-color: #8cad20;
  border-color: #8cad20;
}
