:root {
  --heart-width: 36px;
  --heart-active-margin: 0 20px;
  --heart-rotation: 0;
  --bubbles-left: 12px;
}

/* On screens that are 480px or less */
@media screen and (max-width: 480px) {
  :root {
    --heart-width: 24px;
    --heart-active-margin: 0 10px;
    --bubbles-left: 5px;
  }
}

.hearts-container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 65px;
  padding-top: 80px;
}

.hearts-container.game-over {
  padding-top: 15px;
}

.heart {
  position: relative;
  width: var(--heart-width);
  height: var(--heart-width);
  line-height: var(--heart-width);
  color: white;
  font-size: 1.2em;
  transition: 0.25s;
}

.heart.active {
  margin: var(--heart-active-margin);
}

.game-over .heart {
  margin: inherit;
}

.heart-img-container {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: var(--heart-width);
  height: var(--heart-width);
  transform: rotate(var(--heart-rotation));
}

.active .heart-img-container {
  transform: scale(1.6) rotate(var(--heart-rotation));
}

.game-over .heart-img-container {
  transform: scale(1) rotate(var(--heart-rotation));
}

.used .heart-img-container {
  /* transform: rotate(0); */
  margin-top: 50px;
}

.speech-bubble {
  line-height: 24px;
  width: fit-content;
  font-size: 1.3em;
  transform: translatey(0px);
  animation: float 5s ease-in-out infinite;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 10px 20px;
  border-radius: 15px;
  /* box-shadow: 5px 5px rgba(83, 206, 236, 0.1); */
  margin-bottom: 25px;

  position: absolute;
  opacity: 0;
  transition: left 0.25s;
  top: -80px;
  left: 0;
  z-index: -2;
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -o-pre-wrap; /* Opera */
  white-space: nowrap; /* Chrome */
  word-wrap: break-word; /* IE */
}

.speech-bubble.reverse {
  left: inherit;
  right: 0;
}

.heart.active .speech-bubble {
  opacity: 1;
  left: -10px;
}

.heart.active .speech-bubble.reverse {
  left: inherit;
  right: -10px;
}

.bubbles {
  transform: translatey(0px);
  animation: float2 5s ease-in-out infinite;
  content: ".";
  /* text-shadow: 5px 5px #83af9b; */
  text-align: center;
  color: rgba(255, 255, 255, 0.2);
  font-size: 25px;
  width: 10px;
  height: 5px;
  line-height: 10px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.2);
  position: absolute;
  display: block;
  top: -30px;
  left: -15px;
  /* box-shadow: 5px 5px #83af9b; */
  z-index: -2;
  opacity: 0;
}

.heart.active .bubbles {
  opacity: 1;
  left: var(--bubbles-left);
}

.heart .heart-img {
  width: 100%;
  animation: heartBeat 0.25s infinite alternate;
  width: var(--heart-width);
  height: var(--heart-width);
  background-size: contain;
  background-repeat: no-repeat;
}

.heart-img.extra {
  cursor: pointer;
  animation-name: heartPlus;
  animation-duration: calc(1s * 1.3);
  animation-timing-function: ease-in-out;
}

.game-over .heart-img {
  transform: scale(1.6);
}

.muted .heart-img {
  animation: none;
}

.heart.used .heart-img {
  transform: scale(1.05);
}

.heart.extra {
  cursor: pointer;
}

.game-over .wrong {
  color: rgb(255, 84, 64);
}

@media screen and (max-width: 720px) {
  .heart-plus {
    left: 8px;
    font-size: 32px;
    top: -3px;
  }
}

@media (prefers-reduced-motion) {
  .heart .heart-img {
    animation: none;
  }
}

@keyframes heartPlus {
  0% {
    transform: scale(1);
  }

  14% {
    transform: scale(1.3);
  }

  28% {
    transform: scale(1);
  }

  42% {
    transform: scale(1.3);
  }

  70% {
    transform: scale(1);
  }
}

@keyframes heartBeat {
  to {
    transform: scale(var(--heartbeat-scale));
  }
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(5px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes float2 {
  0% {
    transform: translatey(2px);
  }
  55% {
    transform: translatey(4px);
  }
  100% {
    transform: translatey(2px);
  }
}
