.game-stats {
  margin-top: 25px;
  min-width: 200px;
}

.game-stats p {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.game-stats p span:last-child {
  color: #ff9800;
}
