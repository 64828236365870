/* fredoka-one-regular - latin */
@font-face {
  font-family: "Fredoka One";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/fedoka/fredoka-one-v12-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/fedoka/fredoka-one-v12-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/fedoka/fredoka-one-v12-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../fonts/fedoka/fredoka-one-v12-latin-regular.woff") format("woff"),
    /* Modern Browsers */
      url("../fonts/fedoka/fredoka-one-v12-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/fedoka/fredoka-one-v12-latin-regular.svg#FredokaOne")
      format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: "7guess";
  src: url("../fonts/fontello/font/7guess.eot?95709193");
  src: url("../fonts/fontello/font/7guess.eot?95709193#iefix")
      format("embedded-opentype"),
    url("../fonts/fontello/font/7guess.woff?95709193") format("woff"),
    url("../fonts/fontello/font/7guess.ttf?95709193") format("truetype"),
    url("../fonts/fontello/font/7guess.svg?95709193#7guess") format("svg");
  font-weight: normal;
  font-style: normal;
}
