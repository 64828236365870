.heart-img {
  background-image: url("../images/heart-regular.png");
}

.heart-img.liar {
  background-image: url("../images/heart-liar.png");
}

.heart-img.rotated {
  background-image: url("../images/heart-rotated.png");
}

.extra .heart-img {
  background-image: url("../images/heart-extra.png");
}

.used .heart-img.liar {
  background-image: url("../images/heart-liar-annoyed.png");
}

.wrong .heart-img.liar {
  background-image: url("../images/heart-liar-happy.png");
}

.wrong .heart-img:not(.liar) {
  background-image: url("../images/heart-facepalm.png");
}

.game-over .heart-img:not(.liar) {
  background-image: url("../images/heart-happy.png");
}

.game-over .heart-img.liar {
  background-image: url("../images/heart-liar-annoyed.png");
}

.game-over.lost .heart-img.liar {
  background-image: url("../images/heart-liar-happy.png");
}

.game-over.lost .heart-img:not(.liar) {
  background-image: url("../images/heart-sad.png");
}

.game-over.lost .wrong .heart-img:not(.liar) {
  background-image: url("../images/heart-facepalm.png");
}
