@media screen and (max-width: 719px) {
  .input-form {
    justify-self: flex-end;
    margin-top: auto;
  }
}

.input-field {
  text-align: center;
  border: none;
  padding: 10px;
  border-bottom: 2px solid white;
  outline: none;
  font-size: var(--input-font-size);
  color: white;
  font-family: "Fredoka One", cursive;
  border-radius: 0;
  margin: 0 auto 15px;
  position: relative;
  overflow: hidden;
}

@keyframes caretBlink {
  50% {
    opacity: 0;
  }
}

.input-field::after {
  content: "";
  position: absolute;
  background-color: white;
  margin-left: 4px;
  width: 2px;
  height: 70%;
  top: 15%;
  animation: caretBlink 1s step-start 0s infinite;
}

.input-field.empty {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-field.empty::after {
  left: calc(50% - 2px);
  margin-left: 0;
}

.input-field.empty span:first-child {
  color: rgba(83, 206, 236, 1);
}

.input-field.empty span:nth-child(2) {
  font-size: 0.3em;
  margin: 5px 5px 0;
}

.input-field.empty span:last-child {
  color: #e77fe3;
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  30% {
    transform: translateX(-3px);
  }
  80% {
    transform: translateX(3px);
  }
}

@keyframes borderBlink {
  0%,
  50%,
  100% {
    border-color: white;
  }

  25%,
  75% {
    border-color: red;
  }
}

.error .input-field {
  animation: shake 0.125s ease-in-out 0s infinite,
    borderBlink 0.25s ease-in 0s infinite;
}
