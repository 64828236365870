header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-bottom: var(--header-margin-bottom);
}

.logo {
  line-height: 0;
}

.logo img {
  height: 64px;
}

#volume-icon {
  cursor: pointer;
}

.icon-volume-off {
  color: gray;
}

.icon-volume-on {
  color: white;
}

.heart-plus-icon {
  position: relative;
}

.extra-lives {
  position: absolute;
  top: 6px;
  left: -12px;
  background: #21252ba3;
  padding: 1px 4px;
  border-radius: 10px;
}

.menu-container {
  width: 64px;
}

.menu-container.left {
  display: flex;
  justify-content: space-between;
}

.menu-container > svg {
  margin-right: 10px;
}

.menu-container.right {
  padding-left: 12px;
}

.menu-container.right > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.menu-container.right .icon-heart {
  position: relative;
}

.menu-container.right .icon-heart span {
  position: absolute;
  left: 7px;
  font-size: 20px;
  top: -1px;
}

.menu-container.right .icon-flash {
  margin: 0 6px;
  color: #ff9800;
}

.menu-container.right .span {
  transition: transform 0.5s ease-in-out;
}

@keyframes headShake {
  0% {
    transform: scale(3) translateX(0);
  }

  13% {
    transform: scale(3) translateX(-6px) rotate(-9deg);
  }

  36% {
    transform: scale(3) translateX(5px) rotate(7deg);
  }

  42% {
    transform: scale(3) translateX(-3px) rotate(-5deg);
  }

  63% {
    transform: scale(3) translateX(2px) rotate(3deg);
  }

  86% {
    transform: scale(3) translateX(-3px) rotate(-5deg);
  }

  100% {
    transform: scale(1) translateX(0);
  }
}

.highlight-multiplier span {
  animation: headShake 0.5s ease-in-out 1;
}

.home-button {
  justify-self: flex-end;
  margin-left: auto;
}

.icon-spin5 {
  animation: spin 2s infinite linear;
}

@keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
