.flying-board-parts-container {
  position: absolute;
  z-index: -5;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

@media (prefers-reduced-motion) {
  .flying-board-parts-container {
    display: none;
  }
}

.flying-board-parts.hidden {
  opacity: 0;
}

.flying-board-parts {
  width: 100%;
  height: 100%;
}

.flying-board-parts.bounce {
  animation: heartBeat 0.25s infinite alternate;
}

@media screen and (max-width: 720px) {
  .flying-board-parts {
    overflow: hidden;
  }
}

.flying-board-parts > div {
  position: absolute;
  --starting-point: 120vh;
  top: -20vh;
  opacity: 0;
  -webkit-transition: opacity 1s linear;
  -moz-transition: opacity 1s linear;
  -ms-transition: opacity 1s linear;
  -o-transition: opacity 1s linear;
  transition: opacity 1s linear;
}

@keyframes animateFlyingBoardParts {
  0% {
    transform: translateY(var(--starting-point)) rotate(0deg);
    opacity: 0;
  }

  50% {
    transform: translateY(calc(var(--starting-point) / 2)) rotate(360deg);
    opacity: 0.2;
  }

  100% {
    transform: translateY(0) rotate(720deg);
    opacity: 0;
  }
}

@keyframes heartBeat {
  to {
    transform: scale(1.1);
  }
}
