.up {
  color: #fe6e8a;
}

.down {
  color: rgba(83, 206, 236, 1);
}

.won {
  color: #8cad20;
}

.lost {
  color: rgba(255, 255, 255, 0.5);
}

button.share-button {
  background-color: inherit;
  margin-top: 15px;
  display: flex;
  align-items: center;
  color: rgba(83, 206, 236, 1);
}

button.share-button i {
  margin: 0 5px;
}

.game-play-container {
  width: 90%;
  /* overflow: hidden; */
  padding: 0 15px;
}

.warning-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(33, 37, 43, 0.85);
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  backdrop-filter: blur(10px);
  padding-top: 150px;
}

.warning-modal > div {
  max-width: min(320px, 80%);
}

.warning-modal .title {
  font-size: 24px;
  margin-bottom: 15px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
  padding: 15px;
}

.warning-modal .message {
  font-size: 24px;
  margin-bottom: 50px;
  line-height: 48px;
}

.warning-modal span {
  color: #ff9800;
}

.warning-modal .keep-playing {
  background-color: #8cad20;
  border-color: #8cad20;
  font-size: 2em;
}

.warning-modal .quit-game {
  background-color: #ff9800;
  border-color: #ff9800;
  font-size: 1.5em;
}
