.progress-bar {
  width: 80%;
  height: 30px;
  margin: 0 auto 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;
  opacity: 1;
  transition: height 0.25s, opacity 0.25s;
}

.bar-container {
  flex: 1;
  display: inline-block;
  /* */
  background-color: gray;
  height: 4px;
  border-radius: 2px;
}

.bar-progress {
  height: 4px;
  width: 20%;
  background-color: white;
  border-radius: 3px;
  position: relative;
}

@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.current-score {
  position: absolute;
  right: 15px;
  /* text-shadow: -2px 0 #21252a, 0 2px #21252a, 2px 0 #21252a, 0 -2px #21252a; */
  background: #ff6f89;
  border-radius: 15px;
  padding: 5px 5px;
}

.progress-bar.reverse .current-score {
  right: inherit;
  left: 15px;
}

.current-score.visible {
  animation: tada 1s ease-in-out;
  opacity: 1;
}

.current-score.hidden {
  transition: opacity 1s ease-in-out;
  opacity: 0;
}
