.about-page {
  padding-top: 30px;
}

.developer h2 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  font-size: 1em;
}

.developer img {
  margin: 0 10px;
}

.developer p:first-of-type {
  font-size: 2em;
  margin-bottom: 10px;
}
