:root {
  --input-switch-button-color: #fe6e8a;
  --input-switch-border-color: #fff;
  --input-switch-disabled-color: rgba(255, 255, 255, 0.5);
  --box-shadow-color-2: rgba(0, 0, 0, 0.2);
  --rgb-color-lime: 136, 231, 127;
  --input-switch-enabled-color: rgb(var(--rgb-color-lime));
}

.switch {
  position: relative;
  display: inline-block;
}
.switch-input {
  display: none;
}
.switch-label {
  display: block;
  width: 48px;
  height: 12px;
  text-indent: -150%;
  clip: rect(0 0 0 0);
  color: transparent;
  user-select: none;
  font-size: 1px;
  overflow: hidden;
}
.switch-label::before,
.switch-label::after {
  content: "";
  display: block;
  position: absolute;
  cursor: pointer;
  box-sizing: border-box;
}
.switch-label::before {
  width: 100%;
  height: 12px;
  top: 6px;
  background-color: var(--input-switch-disabled-color);
  border-radius: 45%;
  -webkit-transition: background-color 0.1s ease;
  transition: background-color 0.1s ease;
}
.switch-label::after {
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  border-radius: 35%;
  background-color: var(--input-switch-button-color);
  border: 2px solid var(--input-switch-border-color);
  box-shadow: 0 0 2px var(--box-shadow-color-2);
  -webkit-transition: left 0.1s ease;
  transition: left 0.1s ease;
}
.switch-input:checked + .switch-label::before {
  background-color: var(--input-switch-enabled-color);
}
.switch-input:checked + .switch-label::after {
  left: 24px;
}
