.start-playing-link {
  margin-top: 45px;
  display: block;
  margin-bottom: 15px;
}

.input-group {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 24px;
}

.input-group > label {
  align-self: center;
}

.how-to-play-button {
  background-color: rgba(83, 206, 236, 1);
  font-size: 1.2em;
  margin-bottom: 15px;
}

.about-button {
  font-size: 1em;
  background-color: inherit;
  margin-bottom: 0;
}
